<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <button class="btn btn-primary"
                                        v-if="data.permission.canCreate"
                                        @click="modalOpen">
                                    {{ translations?.create_new }}
                                </button>
                            </div>
                            <div class="expand-ctl">
                                <p>
                                    <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseProject-ctl" aria-expanded="false"
                                            aria-controls="collapseProject-ctl">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor"
                                             class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                                        </svg>
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div class="collapse mt-3" id="collapseProject-ctl">
                            <table-filter :translations="translations" @change="getFilteredValue"/>
                        </div>
                    </div>
                    <data-table :translations="translations" :options="dataList.options"
                                :data-set="data.dataList"
                                :page-size="data.pageSize"
                                @change-action="changeAction"
                                @pagination="pagination"
                                @limit-change="limitChange"/>
                </div>
            </div>
        </div>
    </section>

    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="addGlosarryModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <add-edit-glossary v-if="dataList.isModalActive"
                               :form-error="dataList.formError"
                               :form-data="dataList.formData"
                               @submit-form="submitForm"/>
        </div>
    </div>
    <!-- /Modal -->

</template>

<script>
import HelperFunction from "@/common/helpers";
import {useStore} from "vuex";
import {computed, onMounted, reactive, watch} from "vue";
import DataTable from "@/common/components/datatable";
import AddEditGlossary from "@/views/auth/library/glossary/AddEditGlossary";
import TableFilter from "@/common/components/table-filter/TableFilter";
import {useRoute} from "vue-router";

export default {
    name: "Glossary",
    components: {TableFilter, AddEditGlossary, DataTable},
    setup() {
        const store = useStore();
        const route = useRoute();
        const {data, deleteListData, getAllData, storeFormData, openModal, permissionResolver} = HelperFunction();

        /** watch **/
        permissionResolver(route.path);
        /** watch end **/

        const translations = computed(() => {
            return store.state.translations;  
        });

        let dataList = reactive({
            isModalActive: false,
            params: {
                page: null,
                limit: null,
                sort: 'desc',
                company_id: null,
                search: ''
            },
            formError: {},
            formData: {},
            options: {
                name: translations.value?.glossary || 'Glossary',
                columns: [
                    {
                        title: translations.value?.title || 'Title',
                        type: 'text',
                        key: 'title'
                    },
                    {
                        title: translations.value?.description || 'Description',
                        type: 'text',
                        key: 'description',
                        truncate: true
                    }
                ],
                showAction: true,
                action: [
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: data.permission.canEdit
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: data.permission.canDelete
                    }
                ]
            }
        })

        /** computed **/
        const selectedCompany = computed(() => store.getters.getSelectedBoard);
        /** computed **/


        onMounted(() => {
            getData();
            $('#showModal').on('hidden.bs.modal', (e) => {
                dataList.isModalActive = false;
                dataList.formData = {}
            });
        })
        const getData = () => {
            data.setURL = vueConfig.Library.GlossaryEndPoint;
            dataList.params.company_id = selectedCompany.value.id ? selectedCompany.value.id : null;
            getAllData(data.setURL, dataList.params);
        }

        const modalOpen = () => {
            dataList.isModalActive = true;
            openModal('showModal');
        }

        const submitForm = (formData) => {
            const action = {
                url: formData.id ? `${vueConfig.Library.GlossaryEndPoint}/${formData.id}` : vueConfig.Library.GlossaryEndPoint,
                modalId: 'showModal'
            };
            const submitData = {
                ...formData,
                company_id: selectedCompany.value.id ? selectedCompany.value.id : null
            }
            storeFormData(action, submitData)
                .then(response => {
                    console.log(response, 'response');
                    getData();
                })
                .catch((error) => {
                    if (error.response) {
                        dataList.formError = error.response.data.errors;
                    }
                });
        }
        const search = () => {
            getData();
        }

        /** data table emits**/
        const changeAction = (action, row) => {
            if (action.type === 'edit') {
                modalOpen();
                dataList.formData = row;
            } else if (action.type === 'delete') {
                deleteListData(`${vueConfig.Library.GlossaryEndPoint}/${row.id}`);
            }
        }
        const pagination = (page) => {
            dataList.params.page = page;
            getData();
        }
        const limitChange = (limit) => {
            dataList.params.limit = limit;
            delete dataList.params.page
            getData();
        }

        /** data table emits**/
        function getFilteredValue(params) {
            dataList.params = {...dataList.params, ...params};
            getData();
        }

        return {
            data,
            modalOpen,
            limitChange,
            pagination,
            changeAction,
            dataList,
            submitForm,
            search,
            getFilteredValue,
            translations
        }
    }
}
</script>

<style scoped>

</style>
