<template>
    <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <select class="form-select"
                    v-model="dataList.category_id"
                    @change="filteredValue"
                    aria-label="Project-Title">
                <option selected value="">{{ translations?.all }} All</option>
                <option v-for="(cat, index) in getTemplateCategory"
                        :key="`template-cat-${index}`"
                        :value="cat.id">
                    {{ cat.name }}
                </option>
            </select>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <form @submit.prevent="filteredValue">
                <div class="input-group search-box">
                    <input type="text"
                           class="form-control"
                           :placeholder="translations?.search"
                           v-model="dataList.search"
                           name="search">
                    <button class="btn btn-outline-secondary" type="button">
                        <font-awesome-icon :icon="['fas', `search`]"></font-awesome-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import HelperFunction from "@/common/helpers";
import {reactive} from "vue";

export default {
    name: "TableFilter",
    props: ['translations'],
    emits: ['change'],
    setup(props, {emit}) {
        const {getTemplateCategory} = HelperFunction();
        let dataList = reactive({
            search: '',
            category_id: ''
        })

        const filteredValue = () => {
            let params = {
                search: dataList.search,
                category_id: dataList.category_id
            }
            emit('change', params);
        }

        return {
            dataList,
            getTemplateCategory,
            filteredValue
        }
    }
}
</script>
